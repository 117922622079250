import axios from 'axios';

export const API_ROOT = `https://${window.location.host}/api/manager/`;

const executeRequest = params => {
    if (!params) {
        params = {};
    }

    if (!params.headers) {
        params.headers = {};
    }

    params.headers = {
        ...params.headers
    };

    return axios(params);
};

export const request = {
    del: (url, params, data) => {
        return executeRequest({
            data,
            params,
            method: 'DELETE',
            url: `${API_ROOT}${url}`
        });
    },
    download: (url, params) => {
        return executeRequest({
            params,
            method: 'GET',
            responseType: 'blob',
            url: `${API_ROOT}${url}`
        });
    },
    get: (url, params, data) => {
        return executeRequest({
            params,
            data,
            method: 'GET',
            url: `${API_ROOT}${url}`
        });
    },
    put: (url, data, params) => {
        return executeRequest({
            params,
            data,
            method: 'PUT',
            url: `${API_ROOT}${url}`
        });
    },
    post: (url, data, params) => {
        return executeRequest({
            params,
            data,
            method: 'POST',
            url: `${API_ROOT}${url}`
        });
    },
    upload: (url, data, file, params, config = {}) => {
        if (!data) {
            data = {};
        }

        const formData = new FormData();

        Object.keys(data).map(prop => {
            formData.append(prop, data[prop]);
        });

        formData.append('file', file);

        return executeRequest({
            method: 'POST',
            url: `${API_ROOT}${url}`,
            data: formData,
            config: {
                headers: { 'Content-Type': 'multipart/form-data' }
            },
            params,
            ...config
        });
    },
    downloadPost: (url, data, params) => {
        return executeRequest({
            params,
            data,
            method: 'POST',
            url: `${API_ROOT}${url}`,
            responseType: 'blob'
        });
    }
};

export const limit = (count, page) => `limit=${count}&page=${page ? page : 1}`;

export const requestToken = params => {
    return axios({
        url: `${API_ROOT}oauth/token`,
        params
    });
};

export default {
    request
};
